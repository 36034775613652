<template>
    <div class="stake">
        <logo class="logo"></logo>
        <div class="box">
            <div class="header">
                <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
                <div class="title">
                    <img src="@/assets/imgs/icons/05b.png" class="title-img" alt="">
                    <span class="text">{{ $t('home.navTitles.stake') }}</span>
                </div>
                <a-icon v-if="!showStake" class="opt-icon" type="thunderbolt" @click="showStake=true" />
                <a-icon v-else class="opt-icon" type="close" @click="showStake=false" />
            </div>
            <div class="stake-box" v-if="showStake">
                <div v-if="!stakeItems.stake_switch_on">
                    {{ '合約質押暫未開啟' }}
                </div>
                <div v-else>
                    <a-row :gutter="16" class="nav-bars">
                        <a-col :span="8" v-for="(item, index) in stakeItems.stake_gift" :key="index">
                            <div class="box nav-bar" :class="currentStateIdx==index?'on':''" @click="currentStateIdx=index">
                                <div class="amount">
                                    {{item[0]}}
                                </div>
                                <div class="rate" v-if="!$store.state.userinfo.tree">
                                    <div> {{ item[1] }}% </div>
                                    <div> {{ item[2] }} 天 </div>
                                </div>
                                <div v-else class="rate rate-tree">
                                    <div class="rate-tree-item">
                                        <img class="tree-icon" src="@/assets/svgs/tree-icon.svg" alt="">
                                        <span> {{ item[3] }}% </span>
                                    </div>
                                    <div class="rate-tree-item">
                                        <!-- <a-icon class="tree-icon" type="arrow-up" /> -->
                                        <span> {{ item[4] }} 天 </span>
                                    </div>
                                </div>
                                
                            </div>
                        </a-col>
                    </a-row>
                    <div class="btn-primary" @click="stakeNow">
                        {{ '質押' }}
                    </div>
                </div>
            </div>
            <div class="container-pd" v-else>
                <div v-if="stakes.length>0" class="stake-items">
                    <div v-for="(st,i) in stakes" :key="'st'+i" class="stake-item" :class="st.status==2?'end':''">
                        <div class="flex id-time">
                            <div class="id">#{{ st.id }}</div>
                            <div class="time">{{ st.created_at }}</div>
                        </div>
                        <div class="amount"> {{ st.amount/1000000 }} </div>
                        
                        <div class="profits">
                            <div class="total-profit">
                                <span>总收益: </span>
                                <span>{{ st.total_profit/1000000 }}</span>
                            </div>
                        </div>
                        <div class="prop-tags">
                            <a-tag color="#f50"> {{ st.rate }}% </a-tag>
                            <a-tag color="#b37e25"> {{ st.days }}{{ $t('stake.days') }} </a-tag>
                            <a-tag v-if="st.status==1" color="green"> 质押中 </a-tag>
                            <a-tag v-if="st.status==2" color="gray"> 已结束 </a-tag>
                        </div>
                        <div v-if="st.status==1 && st.profit>0" class="withdraw-profit" @click="withdrawProfit(st.id)">
                            <span class="profit">{{ st.profit/1000000 }} </span>
                            <a-tag color="#f50"> 提取 </a-tag>
                        </div>
                    </div>
                </div>
                <div v-else>
                    暫時沒有質押數據
                </div>
            </div>
            <!-- <div v-if="showDonate" class="donate-wrapper buddha-bg">
                <donate :donateType="donateType" @handle-close="closeDonate"></donate>
            </div> -->
        </div>
    </div>
</template>
<script>
import Logo from '../components/Logo.vue';

export default {
    name: "Stake",
    data() {
        return {
            loading: false,
            showStake: false,
            currentStateIdx: 0,
            stakeItems: {
                stake_switch_on: false,
                stake_gift: [100,500,1000,3000,5000]
            },
            stakes:[],
        };
    },
    components: { Logo },
    mounted() {
        this.getStakes();

        this.getStakeItems();
    },
    methods: {
        stakeNow(){
            if(this.loading){
                return;
            }
            this.loading = true;
            let _this = this;
            this.$http.call(this.$http.api.stake,{data:{
                id: _this.currentStateIdx
            }}).then(res=>{
                console.log(res.data.data);
                if(res.data.code != '200'){
                    _this.$message.error(res.data.data);
                }else{
                    console.log('done')
                    _this.getStakes();
                    _this.showStake = false;
                }
                // _this.stakeItems = res.data.data;
            },res=>{
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                _this.loading = false;
            });
        },
        getStakeItems() {
            let _this = this
            this.$http.call(this.$http.api.stakeItems).then(res=>{
                console.log(res.data.data);
                _this.stakeItems = res.data.data;
            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            });
        },
        getStakes() {
            let _this = this
            this.$http.call(this.$http.api.stakes).then(res=>{
                console.log(res.data.data);
                _this.stakes = res.data.data;
            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            });
        },
        withdrawProfit(id) {
            if(this.loading) return;
            this.loading = true;
            let _this = this
            this.$http.call(this.$http.api.withdrawStake, {data:{id}}).then(res=>{
                console.log(res.data.data);
                if(res.data.code != '200'){
                    _this.$message.error(res.data.data);
                }
                _this.getStakes();
            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                _this.loading = false;
            });
        }
    },
};
</script>
<style lang="less" scoped>
.stake {
    width:100%;
    height: 100%;
    padding: 0 50px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    .logo {
        padding: 50px 0;
    }
    .box{
        position: relative;
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        .header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px;
            .back {
                position: absolute;
                left: 30px;
                font-size: 20PX;
                color: #a8a8a8;
            }
            .title {
                font-weight: bold;
                font-size: 16PX;
                color: @primary-color;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title-img {
                    height: 100px;
                }
            }
            .opt-icon {
                position: absolute;
                right: 30px;
                font-size: 20PX;
                color: #a8a8a8;
            }
        }
        .stake-box {
            padding: 24px;
            .nav-bars {
                .nav-bar {
                    padding: 15px;
                    color: #aaa;
                    background: #fff;
                    .amount {
                        font-size: 28PX;
                        font-weight: bold;
                        color: @primary-color;
                    }
                    .rate-tree {
                        .rate-tree-item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .tree-icon {
                                width: 30px;
                                margin-right: 10px;
                                img {
                                    width: 100%;
                                }
                            }
                        }
                        
                    }
                }.on{
                    background: @primary-color;
                    color: #dadada;
                    .amount {
                        color: #fff;
                    }
                }
            }
        }
        .container-pd{
            overflow-y: scroll;
            .stake-items { 
                .stake-item {
                    background: #fff;
                    margin: 24px auto;
                    border-radius: 15px;
                    padding: 24px;
                    text-align: left;
                    position: relative;
                    .amount {
                        font-size: 80px;
                        font-weight: bold;
                    }
                    .profits {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .total-profit {
                            font-size: 50px;
                            padding: 15px 0;
                        }
                    }
                    
                    .withdraw-profit {
                        position: absolute;
                        bottom: 15px; right: 15px;
                    }
                }.end{
                    opacity: .7;
                    filter: grayscale(70%);
                }
            }
        }
    }
}
</style>
